import React from "react"
import {Helmet} from "react-helmet";

const text_style = {
    fontSize: '252px',
    fontWeight: '900',
    textTransform: 'uppercase',
    letterSpacing: '-40px',
    marginLeft: '-40px', 
}

const NotFound = () => {
    return(
        <>
        <Helmet>
            <title>RPIP | Page Not Found</title>
        </Helmet>
        <div className="relative h-screen flex justify-center items-center bg-rp-yellow">
            <div className="flex flex-col max-w-lg w-full text-center">
                <h1 style={text_style}  className="text-rp-black">
                    <span style={{textShadow: '-10px 0px 0px #FFFFFF'}}>4</span>
                    <span style={{textShadow: '-10px 0px 0px #FFFFFF'}}>0</span>
                    <span style={{textShadow: '-10px 0px 0px #FFFFFF'}}>4</span>
                </h1>
                <h2 className="text-xl text-rp-black font-medium">We are sorry, but the page you requested was not found.</h2>
            </div>
        </div>
        </>
    )
}

export default NotFound;